import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
  
} from '@mui/material';
import { FileRow } from './FileRow';
import { MobileFileRow } from './MobileFileRow';
import { getDatasetPermission } from './DatasetHelper';

export const FileManagementTable = ({
  data,
  fetching,
  datasetPermissions,
  groupDatasets,
  openFiles,
  setOpenFiles,
  selectedFiles,
  setSelectedFiles,
  refetch,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showFullText, setShowFullText] = useState(undefined);

  // debug show loading in mobile and desktop
  if(isMobile) {
    return (
      <>
        {!fetching && data.map((row, index) => (
          <MobileFileRow
            key={`file-${index}`}
            row={row}
            index={index}
            datasetPermission={getDatasetPermission(row.source_string, datasetPermissions)}
            groupDatasets={groupDatasets}
            openFiles={openFiles}
            setOpenFiles={setOpenFiles}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            refetch={refetch}
          />
        ))}
      </>
    )
  }

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}> 
        <Table 
          sx={{ 
            minWidth: 650,
            backgroundColor: theme.palette.table.body.backgroundColor,
          }}
        >
          <TableHead sx={{backgroundColor: theme.palette.table.header.backgroundColor}}>
            <TableRow>
              <TableCell sx={{ paddingY: 1 }} />
              <TableCell sx={{ paddingY: 1 }} >Filename</TableCell>
              <TableCell align="left" sx={{ paddingY: 1 }} >CUI</TableCell>
              {!groupDatasets && (
                <TableCell align="left" sx={{ paddingY: 1 }} >Dataset</TableCell>
              )}
              <TableCell align="center" sx={{ paddingY: 1 }} >Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!fetching && data.map((row, index) => (
              <FileRow
                key={`file-${index}`}
                row={row}
                index={index}
                groupDatasets={groupDatasets}
                datasetPermission={getDatasetPermission(row.source_string, datasetPermissions)}
                openFiles={openFiles}
                setOpenFiles={setOpenFiles}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                refetch={refetch}
                setShowFullText={setShowFullText}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showFullText && (
        <Dialog
          open={showFullText}
          onClose={() => setShowFullText(undefined)}
          aria-labelledby="plain-data-dialog"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="plain-data-dialog-title">Plain Data Full Text</DialogTitle>
          <DialogContent>
            <Typography>{showFullText}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowFullText(undefined)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  );
}

