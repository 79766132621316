import React, { useState } from "react";
import { Checkbox, IconButton, Stack, Typography } from "@mui/material";
import { FiTrash } from "react-icons/fi";
import { DeleteFilesDialog } from "./DeleteFilesDialog";

export const MobileFileRow = ({ 
  row,
  index,
  datasetPermission,
  selectedFiles,
  setSelectedFiles,
  refetch,
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  
  const isOdd = index % 2 === 0;
  const fileName = `${row.filename}-(${row.source_string})`;
  const extractDatasetName = (input) => {
    const pattern = /user_custom_\d+_([^_]+)_content/;
    const match = input.match(pattern);
    if (match && match[1]) {
        return match[1];
    } else {
        return '';
    }
  }

  return (
    <Stack 
      direction={'column'} 
      spacing={0}
      width={'100%'}
      sx={{
        backgroundColor: isOdd ? '#444444' : '#666666',
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
        <Stack direction={'row'} alignItems={'center'} width={'90%'}>
          <Checkbox
            checked={selectedFiles.includes(row)}
            onChange={(event) => {
              if (event.target.checked) {
                setSelectedFiles([...selectedFiles, row]);
              } else {
                setSelectedFiles(selectedFiles.filter((file) => file !== row));
              }
            }}
          />
          <Typography noWrap textOverflow={'ellipsis'}>{row.filename}</Typography>
        </Stack>
        { datasetPermission === 'owner' && (
        <IconButton
          aria-label="Delete"
          size="small"
          onClick={() => setDeleteDialog(true)}
        >
          <FiTrash />
        </IconButton>)}
      </Stack>
      {deleteDialog && (
        <DeleteFilesDialog
          open={deleteDialog}
          setOpen={setDeleteDialog}
          row={row}
          refetch={refetch}
        />
      )}
    </Stack>
  );
}