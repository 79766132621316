import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Stack, useTheme } from '@mui/material';
import { IoChatbubbles, IoChatbubblesOutline, IoPerson, IoSettingsSharp } from 'react-icons/io5';
import { FiBookOpen, FiLogOut, FiUser, FiMessageSquare, FiCodesandbox, FiTag } from 'react-icons/fi';
import { MdCompare, MdHelp } from 'react-icons/md';
import * as default_data from '../config.js'
import { getUser, logout } from '../AuthService';

export const Navbar = ({ 
  openModal,
  setSettingDrawerOpen,
  setSettingsTabValue,
 }) => {
  const [paid, setPaid] = useState(false);

  const theme = useTheme();

  const iconSize = '22px';
  const menuIconSize = '14px';
  const windowLocation = window.location.pathname;
  const isChatPageActive = windowLocation === '/dashboard' || windowLocation === '/' ? true : false;
  const isWorkbookPageActive = windowLocation === '/workbook' ? true : false;
  const isLeaderboardPageActive = windowLocation === '/leaderboard' ? true : false;
  const isModelComparisonPageActive = windowLocation === '/model-comparison' ? true : false;

  const listItemIconStyle = { minWidth: '30px !important' }
  const listItemStyle = { '&:hover': { color:theme.palette.text.primary, backgroundImage: 'rgba(0,0,0,0.3)'} }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const buttonRef = useRef(null);

  useEffect(() => {
    var user = getUser()
    setPaid(user.paid)
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        color: 'white',
        height: '100%',
        width: '48px',
        paddingY: '16px',
        paddingX: '8px',
        borderRight: '1px solid #505050',
      }}
    >
      <Stack direction="column" spacing={2} alignItems="center">
        <img src="/logo.png" alt="Ask Sage" className="logo" width={'40px'} />
        <Tooltip title="Chat" placement='right'>
          <IconButton component={'a'} href={'/'} disabled={isChatPageActive} >
            {isChatPageActive ? <IoChatbubbles size={iconSize} fill={theme.palette.text.primary} /> : <IoChatbubblesOutline size={iconSize}/>}
          </IconButton>
        </Tooltip>
        { paid &&
        <Tooltip title="Workbook" placement='right'>
          <IconButton component={'a'} href={'/workbook'} disabled={isWorkbookPageActive} target="_blank">
            {isWorkbookPageActive ? <FiBookOpen size={iconSize} fill={theme.palette.text.primary} /> : <FiBookOpen size={iconSize}/>}
          </IconButton>
        </Tooltip>
        }
        {/* <IconButton component={'a'} href={'/leaderboard'} disable={isLeaderboardPageActive} >
          {isLeaderboardPageActive ? <MdLeaderboard size={iconSize} fill='white' /> : <MdOutlineLeaderboard size={iconSize}/>}
        </IconButton> */}
        <Tooltip title="Model Compare" placement='right'>
          <IconButton component={'a'} href={'/model-comparison'} disabled={isModelComparisonPageActive}>
            {isModelComparisonPageActive ? <MdCompare size={iconSize} fill={theme.palette.text.primary}/> : <MdCompare size={iconSize}/>}
          </IconButton>
        </Tooltip>
        { paid &&
        <Tooltip title="In a Box" placement='right'>
          <IconButton 
            component={'a'}
            href={`https://${default_data.default_box_prefix_url}.${default_data.default_tenantDomain}`}
            target="_blank"
          >
            <FiCodesandbox size={iconSize}/>
          </IconButton>
        </Tooltip>}
      </Stack>
      <Stack direction="column" spacing={1} alignItems="center">
        { openModal && (
        <Tooltip title="Help">
          <IconButton onClick={openModal} >
            <MdHelp size={iconSize} />
          </IconButton>
        </Tooltip>
        )}

      <Tooltip title="Account" placement='right'>
        <IconButton
          ref={buttonRef}
          onClick={handleClick}
        >
          <IoPerson size={iconSize} />
        </IconButton>
      </Tooltip>
        { setSettingDrawerOpen &&
        <Tooltip title="Settings" placement='right'>
          <IconButton
            onClick={()=>{
              setSettingDrawerOpen(true)
              setSettingsTabValue('customization')
            }} 
          >
            <IoSettingsSharp size={iconSize} />
          </IconButton>
        </Tooltip>
        }
        {open && (
          <Menu
            id={'account-menu'}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MenuList dense>
              <MenuItem component={'a'} href={'/update'} sx={listItemStyle} target="_blank">
                <ListItemIcon sx={listItemIconStyle}>
                  <FiUser size={menuIconSize}/>
                </ListItemIcon>
                <ListItemText primary="Account" />
              </MenuItem>  
              {setSettingDrawerOpen &&
                <MenuItem
                  onClick={()=>{
                    setAnchorEl(null)
                    setSettingDrawerOpen(true)
                    setSettingsTabValue('tokens')
                  }} 
                  sx={listItemStyle}
                >
                  <ListItemIcon sx={listItemIconStyle}>
                    <FiTag size={menuIconSize}/>
                  </ListItemIcon>
                  <ListItemText primary="Subscription" />
                </MenuItem>
              }
              { default_data.default_block_discord === true ? '' : 
                <MenuItem 
                  component={'a'}
                  href="https://discord.gg/upxuAdNvjY"
                  target="_blank"
                  rel="noopener"
                  sx={listItemStyle}
                >
                  <ListItemIcon sx={listItemIconStyle}>
                    <FiMessageSquare size={menuIconSize}/>
                  </ListItemIcon>
                  <ListItemText primary="Discord" />
                </MenuItem>
              }
              <MenuItem onClick={logout} sx={listItemStyle}>
                <ListItemIcon sx={listItemIconStyle}>
                  <FiLogOut size={menuIconSize}/>
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
              {/* <button variant="light" onClick={call_logout}>Logout <FiLogOut /></button> */}
            </MenuList>
          </Menu>
        )}
      </Stack>
    </Stack>
  );
};
