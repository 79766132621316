import { useState, useEffect, useCallback, useRef } from 'react';
import * as default_data from '../../config.js'
import { getToken } from '../../AuthService.js';

export const useFetchFiles = () => {
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const hasFetched = useRef(false); // Ref to track fetch status

  const fetchFiles = async () => {
    const token = getToken();
    if (token === false) {
      setFetching(false);
      return;
    }

    setFetching(true);
    setError(null);

    try {
      const response = await fetch(default_data.default_chat_service_url + '/get-all-files-ingested', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`
        },
        body: JSON.stringify({})
      });

      if (!response.ok) {
        throw new Error('An error occurred while getting data.');
      }

      const result = await response.json();
      if (result.status !== 200) {
        throw new Error('Invalid status code received.');
      }

      setData(result.response);
    } catch (err) {
      setError(err.message);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (!hasFetched.current && data === null) {
      hasFetched.current = true;
      fetchFiles();
    }
  }, []); // Empty dependency array ensures this runs once

  return { data, fetching, error, refetch: fetchFiles };
};

export const fetchChunksByDataset = (datasetArray) => {
  const token = getToken();
  if (token === false) {
    return;
  }

  try {
    return fetch(default_data.default_chat_service_url + '/get-dataset-results', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },
      body: JSON.stringify({ datasets: datasetArray })
    })
    .then(response => response.json())
    .then(data => {
      return data
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const deleteDataset = (dataset) => {
  const token = getToken();
  if (token === false) {
    return;
  }

  try {
    return fetch(default_data.default_chat_service_url + '/dataset', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`,
      },
      body: JSON.stringify({ dataset: dataset })
    })
    .then(response => response.json())
    .then(data => {
      return data
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const useCopyFiles = () => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);

  const copyFiles = useCallback(async (selectedFiles, selectedDataset) => {
    const token = getToken();

    if (!token) {
      setError('Authentication failed. Please log in again.');
      return false; 
    }

    setFetching(true);
    setError(null);
    setWarning(null);

    try {
      const response = await fetch(`${default_data.default_chat_service_url}/copy-files-dataset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': token, 
        },
        body: JSON.stringify({ 
          files: selectedFiles, 
          dataset: selectedDataset 
        }),
      });

      if (!response.ok) {
        let errorMessage = 'Internal error, please contact customer support.';
        try {
          const errorData = await response.json();
          if (errorData && errorData.message) {
            errorMessage = errorData.message;
          }
        } catch (parseError) {
          //Persist the original error message
        }
        throw new Error(errorMessage);
      }

      
      let result;
      try {
        result = await response.json();
      } catch (jsonError) {
        throw new Error('Failed to parse server response.');
      }

      if (typeof result.status !== 'number') {
        throw new Error('Invalid response from server.');
      }

      if (result.status !== 200) {
        const serverMessage = result.message || 'Internal error, please contact customer support.';
        throw new Error(serverMessage);
      }
      
      if (result.response) {
        setWarning(result.response);
      }
      return true; 
    } catch (err) {
      
      if (err.name === 'TypeError') {
        setError('Network error. Please check your connection and try again.');
      } else {
        setError(err.message || 'An unexpected error occurred.');
      }
      return false; 
    } finally {
      setFetching(false);
    }
  }, []);

  return { fetching, warning, error, copyFiles };
};

export const useDeleteFiles = () => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);

  const deleteFile = useCallback(async (dataset, filename) => {
    const token = getToken();

    if (!token) {
      setError('Authentication failed. Please log in again.');
      return false; 
    }

    setFetching(true);
    setError(null);
    setWarning(null);

    try {
      const response = await fetch(`${default_data.default_chat_service_url}/delete-filename-from-dataset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': token, 
        },
        body: JSON.stringify({ 
          dataset: dataset, 
          filename: filename 
        }),
      });

      if (!response.ok) {
        let errorMessage = 'Internal error, please contact customer support.';
        try {
          const errorData = await response.json();
          if (errorData && errorData.message) {
            errorMessage = errorData.message;
          }
        } catch (parseError) {
          //Persist the original error message
        }
        throw new Error(errorMessage);
      }

      
      let result;
      try {
        result = await response.json();
      } catch (jsonError) {
        throw new Error('Failed to parse server response.');
      }

      if (typeof result.status !== 'number') {
        throw new Error('Invalid response from server.');
      }

      if (result.status !== 200) {
        const serverMessage = result.message || 'Internal error, please contact customer support.';
        throw new Error(serverMessage);
      }
      
      if (result.response) {
        setWarning(result.response);
      }
      return true; 
    } catch (err) {
      if (err.name === 'TypeError') {
        setError('Network error. Please check your connection and try again.');
      } else {
        setError(err.message || 'An unexpected error occurred.');
      }
      return false; 
    }
    finally {
      setFetching(false);
    }
  }, []);
    
  return { fetching, warning, error, deleteFile };
}

export const assignUserToDataset = (dataset, email) => {
  const token = getToken();
  if (token === false) {
    return;
  }

  try {
    return fetch(default_data.default_user_service_url_no_cac + '/assign-dataset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },
      body: JSON.stringify({ dataset: dataset, email: email })
    })
    .then(response => response.json())
    .then(data => {
      return data
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const updatePermissionDataset = (dataset, email, permissions) => {
  const token = getToken();
  if (token === false) {
    return;
  }

  try {
    return fetch(default_data.default_user_service_url_no_cac + '/update-permission-dataset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },
      body: JSON.stringify({ dataset: dataset, email: email, permissions: permissions})
    })
    .then(response => response.json())
    .then(data => {
      return data
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const deassignUserFromDataset = (dataset, email) => {
  const token = getToken();
  if (token === false) {
    return;
  }

  try {
    return fetch(default_data.default_user_service_url_no_cac + '/deassign-dataset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },
      body: JSON.stringify({ dataset: dataset, email: email })
    })
    .then(response => response.json())
    .then(data => {
      return data
    });
  }
  catch (err) {
    console.log(err);
  }
}

// Exported function to fetch assigned users with robust error handling
export const fetchAssignedUsers = (dataset) => {
  const token = getToken();
  if (!token) {
    // send the user back to the login page
    window.location.href = '/login';
    return Promise.reject(new Error('Authentication token is not available.'));
  }

  try {
    return fetch(`${default_data.default_user_service_url_no_cac}/get-dataset-assignments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`,
      },
      body: JSON.stringify({ dataset: dataset }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(
              `Server responded with status ${response.status}: ${errorData.message || response.statusText}`
            );
          });
        }
        return response.json();
      })
      .catch((error) => {
        console.error('An error occurred during fetch:', error);
        throw error;
      });
  } catch (err) {
    console.error('An unexpected error occurred:', err);
    return Promise.reject(err);
  }
};