export const getDatasetPermission = (dataset, datasetPermissions) => {
    if (datasetPermissions === undefined || datasetPermissions === null) {
      return 'none';
    }
    const dataset_obj = datasetPermissions.find((item) => item.dataset === dataset);
    if (dataset_obj) {
      return dataset_obj.permissions;
    } else {
      return 'none';
    }
  };

export const extractDatasetName = (input) => {
    const pattern = /user_custom_\d+_([^_]+)_content/;
    const match = input.match(pattern);
    if (match && match[1]) {
        return match[1];
    } else {
        return '';
    }
  }
