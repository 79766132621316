import * as default_data from '../../config.js';
import { getToken, logout, setWithExpiry } from '../../AuthService.js';
import { useState, useEffect, useCallback } from 'react';

const handleResponseError = (response) => {
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
};

export const useFetchMonthlyTokens = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchMonthlyTokens = useCallback(async () => {
    setLoading(true);
    setError(null);
    const token = getToken();
    if (!token) {
      logout();
      return;
    }
    try {
      const response = await fetch(default_data.default_chat_service_url + '/count-monthly-tokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`,
        },
        body: JSON.stringify({}),
      });
      handleResponseError(response);
      const result = await response.json();
      setData(result.response);
    } catch (err) {
      setError(err);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (data === null) {
      fetchMonthlyTokens();
    }
  }, [fetchMonthlyTokens, data]);

  return { data, loading, error, refetch: fetchMonthlyTokens };
};

export const useFetchTeachTokens = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTeachTokens = useCallback(async () => {
    setLoading(true);
    setError(null);
    const token = getToken();
    if (!token) {
      logout();
      return;
    }
    try {
      const response = await fetch(default_data.default_chat_service_url + '/count-monthly-teach-tokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`,
        },
        body: JSON.stringify({}),
      });
      handleResponseError(response);
      const result = await response.json();
      setData(result.response);
    } catch (err) {
      setError(err);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (data === null) {
      fetchTeachTokens();
    }
  }, [fetchTeachTokens, data]);

  return { data, loading, error, refetch: fetchTeachTokens };
};

export const useFetchMaxTokens = () => {
  const [maxInferenceTokens, setMaxInferenceTokens] = useState(null);
  const [maxTeachTokens, setMaxTeachTokens] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTeachTokens = useCallback(async () => {
    setLoading(true);
    setError(null);
    const token = getToken();
    if (!token) {
      logout();
      return;
    }
    try {
      const response = await fetch(default_data.default_user_service_url_no_cac + '/validate_token_with_full_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`,
        },
        body: JSON.stringify({}),
      });
      handleResponseError(response);
      const result = await response.json();
      setMaxInferenceTokens(result.response.max_tokens);
      setMaxTeachTokens(result.response.max_train_tokens);
    } catch (err) {
      setError(err);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (maxInferenceTokens === null || maxTeachTokens === null) {
      fetchTeachTokens();
    }
  }, [fetchTeachTokens, maxInferenceTokens, maxTeachTokens]);

  return { maxInferenceTokens, maxTeachTokens, loading, error, refetch: fetchTeachTokens };
};

export const useFetchUser = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUser = useCallback(async () => {
    setLoading(true);
    setError(null);
    const token = getToken();
    if (!token) {
      logout();
      return;
    }
    try {
      const response = await fetch(default_data.default_user_service_url_no_cac + '/validate_token_with_full_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`,
        },
        body: JSON.stringify({}),
      });
      handleResponseError(response);
      const result = await response.json();
      setWithExpiry('user', JSON.stringify(result.response));
      setUser(result.response);
    } catch (err) {
      setError(err);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user === null) {
      fetchUser();
    }
  }, [fetchUser, user]);

  return { user, loading, error, refetch: fetchUser };
};
