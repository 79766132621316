import React, { useState } from 'react';
import { GroupContainer } from './GroupContainer';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert, Typography, CircularProgress } from '@mui/material';
import { deleteDataset } from './queries';
import { ShareDatasetDialog } from './ShareDatasetDialog';

export const GroupedFiles = ({
  data,
  openFiles,
  datasetPermissions,
  setOpenFiles,
  openDatasets,
  setOpenDatasets,
  selectedFiles,
  setSelectedFiles,
  setDatasets,
  isFiltering,
  refetch,
}) => {
  const [dataSetToDelete, setDataSetToDelete] = useState('');
  const [datasetToShare, setDatasetToShare] = useState(undefined);

  // State variables for loading and error handling
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  const extractDatasetName = (input) => {
    const pattern = /user_custom_\d+_([^_]+)_content/;
    const match = input.match(pattern);
    if (match && match[1]) {
      return match[1];
    } else {
      return '';
    }
  };

  const handleDeleteDataset = () => {
    setIsDeleting(true);
    setDeleteError('');

    deleteDataset(dataSetToDelete)
      .then((res) => {
        if (res.status === 200) {
          setDatasets((datasets) => datasets.filter((dataset) => dataset !== dataSetToDelete));
          setDataSetToDelete('');
        } else {
          setDeleteError('Failed to delete the dataset. Please try again.');
        }
      })
      .catch((error) => {
        console.error(error);
        setDeleteError('An error occurred while deleting the dataset.');
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <>
      {data
        .sort((a, b) => {
          const aHasFiles = a.files.length > 0;
          const bHasFiles = b.files.length > 0;
          if (!aHasFiles && bHasFiles) {
            return 1;
          }
          if (aHasFiles && !bHasFiles) {
            return -1;
          }
          if (aHasFiles && bHasFiles) {
            return a.source.localeCompare(b.source);
          }
          return 0;
        })
        .filter((group) => {
          if (isFiltering) {
            return group.files.length > 0;
          }
          return true;
        })
        .map((group, index) => (
          <GroupContainer
            index={index}
            datasetPermissions={datasetPermissions}
            key={index}
            group={group}
            openDatasets={openDatasets}
            setOpenDatasets={setOpenDatasets}
            openFiles={openFiles}
            setOpenFiles={setOpenFiles}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            setDataSetToDelete={setDataSetToDelete}
            setDatasetToShare={setDatasetToShare}
            refetch={refetch}
          />
        ))}
      {datasetToShare && (
        <ShareDatasetDialog
          datasetPermissions={datasetPermissions}
          datasetToShare={datasetToShare}
          open={datasetToShare !== undefined}
          setOpen={setDatasetToShare}
        />
      )}
      {dataSetToDelete && (
        <Dialog
          open={dataSetToDelete !== undefined}
          onClose={() => {
            setDataSetToDelete('');
            setDeleteError(''); // Clear error when modal is closed
          }}
        >
          <DialogTitle>Delete Dataset</DialogTitle>
          <DialogContent>
            <Typography variant="body1" mb={1}>
              Are you sure you want to delete {extractDatasetName(dataSetToDelete)}?
            </Typography>
            <Typography variant="body2" paragraph>
              This action will also delete all files within the dataset and cannot be undone.
            </Typography>
            {deleteError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {deleteError}
              </Alert>
            )}
            {isDeleting && (
              <Box display="flex" justifyContent="center" my={2}>
                <CircularProgress />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteDataset}
              disabled={isDeleting}
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                setDataSetToDelete('');
                setDeleteError('');
              }}
              disabled={isDeleting}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};